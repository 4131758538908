@import "styles/constants.scss";

.OrderStep {
  text-align: center;
}
.SurveyBox {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.ButtonSpacer {
  padding: 10px;
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ScalerCarousel {
  transform: scale(1.5);
  padding-top: 70px;
  padding-bottom: 70px;
}
.WrappedReplies {
  display: flex;
  width: 100%;
  justify-content: space-around;
  user-select: none;
  height: 100%;
}
.Boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  align-items: flex-start;
}
.Title {
  font-size: $xxlarge-font-size;
  color: $black;
  font-weight: medium;
}

.AlignRight {
  text-align: right;
}
.CheckboxColumn {
  width: 100%;
}

.AlignMiddle {
  place-self: center;
}

.AlignLeft {
  text-align: left;
}

.WrappedCarousel {
  display: flex;
}

.CarouselArrow {
  color: $primary !important;
  font-size: $xxxlarge-font-size;
}

.OrderContent {
  height: 100%;
  padding-bottom: 70px;
}

.AdditionalActionsWrapper {
  position: absolute;
  top: -15px;
  right: -15px;
}
.InlineText {
  text-align: center;
  font-size: 26px;
}
