.slider-number {
    text-align: center;
    align-items: center;
    display: flex;
    font-size: 45px;
    user-select: 'none';
    justify-content: center;

    .slick-current {
        font-size: 90px;
        line-height: 100px;
        font-weight: 500 !important;
        color: #211452 !important;
    }

    .slick-slide {
        color: grey;
        transition: all .3s;
        font-weight: 100;
        height: 130px !important;
        align-content: center !important;

    }

    :has(+ .slick-current) {
        font-size: 55px;
    }

    .slick-current+* {
        font-size: 55px;
    }
}