.Mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 200px;
    border-left: 2px solid #211452;
    border-right: 2px solid #211452;

}

.SliderContainer {
    position: relative;
    width: 100%;
}