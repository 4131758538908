// change colors into the webpack congif too
$primary: #211452;
$primary-light: #3889c8;
$accent: #1d71b8;
$white: #fff;
$black: #4a4a48;
$green: #41c57a;
$yellow: #F8A11F;
$sand: #f2f2f2;
$red: #9b4f86;
$grey: #b9b9b9;
$light-grey: #BFBFBF;
$width: 80%;
$transition-time: 0.3s;
$radius-default: 10px;
$default-font-size: 15px;
$medium-font-size: 18px;
$large-font-size: 28px;
$xlarge-font-size: 38px;
$xxlarge-font-size: 48px;
$xxxlarge-font-size: 65px;
$tagline-font-size : $xlarge-font-size;
$touchscreen-font-size: 90px;
$default-margin: 50px;
$footer-height: 100px;
$header-height: 100px;
$input-height: 70px;

$button-height: 100px;
$small-button-height: 70px;
$success: $green;
$info: $sand;
$error: #d64949;
$warning: $black;