@import "styles/constants.scss";

.DoctorCard {
    height: 250px;
    width: 330px;
    position: relative;
    background: $white;
    display: flex;
    border-radius: 20px;
    font-weight: medium;
    font-size: $medium-font-size;
    color: $primary;
    flex-direction: column;
    cursor: pointer;
    border: 2px solid $white;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px #00000040;
    padding: 20px;
    text-align: center;
}

.SearchButton {
    cursor: pointer;
    font-size: $large-font-size;
    color: $primary-light;
    padding-bottom: $default-margin;
    display: flex;
    flex-direction: row;
    gap: 20px;
    text-align: center;
}

.NoResult {
    color: $light-grey;
    font-size: $large-font-size;
}