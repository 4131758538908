@import "styles/constants.scss";

.Modal {}

.CGUContent {
  overflow-y: scroll;
  height: 55vh;
}

.iconTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelConsultationContent {
  font-size: $xxlarge-font-size;
  color: $primary;
  line-height: 70px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.title__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__icon {
  width: 6rem;
  margin-bottom: 2rem;
}

.title__icon img {
  width: 100%;
}

.title__text {
  font-weight: bold;
  font-size: 65px;
  text-align: center;
  color: #211452;
}

.NoResult {
  color: $light-grey;
  font-size: $large-font-size;
}

.DoctorCard {
  position: relative;
  background: $white;
  display: flex;
  border-radius: 20px;
  font-weight: medium;
  font-size: $medium-font-size;
  color: $primary;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid $white;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 0px #00000040;
  text-align: center;
  width: 300px;
  height: 100px;
  padding: 20px;
}

.ContainerResult {
  height: 150px;
  margin-bottom: -20px;
  width: 100%;
  align-content: center;
}